import { configureStore, isPlain, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit'
import { Iterable } from 'immutable'

import chatReducer from './chat'
import streamReducer from './stream'

// axios
import axios from 'axios';

// remote
export const serverBackend = 'remote'
export const baseUrlWs = 'socket-vc.ominddev.id';
export const baseUrlPeer = 'peer-vc.ominddev.id';
// export const baseUrlPeer = 'stream.psbb.id';

// local
// export const serverBackend = 'local'
// export const baseUrlWs = 'https://socket-klopinaj.ominddev.id';
// export const baseUrlPeer = 'https://peer-klopinaj.ominddev.id';

export const baseAxios = axios.create({
  baseURL: baseUrlWs
});

// backend Klopin
export const klopinUrl = 'https://klopinaj.ominddev.id/api';
export const baseKlopin = axios.create({
  baseURL: klopinUrl
});

// middleware
const isSerializable = (value) => Iterable.isIterable(value) || isPlain(value)

const getEntries = (value) => Iterable.isIterable(value) ? value.entries() : Object.entries(value)

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable,
  getEntries,
})

export default configureStore({
  reducer: {
    chat: chatReducer,
    stream: streamReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
});
