import React, { useEffect, useState } from 'react'
import NonParticipant from '../components/Stream/NonParticipant';
import Participant from '../components/Stream/Participant';
import { useParams, useSearchParams } from 'react-router-dom';
import { readRoom, setRoom, setParticipant, resetRoom } from '../store/stream/function';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import End from './End'

import SlicingParticipant from '../components/Stream/SlicingParticipant';

export default function Stream() {
  // get data
  const { id } = useParams();
  const dispatch = useDispatch()
  const [data, setData] = useState('')
  const navigate = useNavigate()
  
  const [loading, setLoading] = useState(true)
  const [searchParams] = useSearchParams();
  const getToken = searchParams.get('token');
  const getIdUser = searchParams.get('idUser');
  const getIdLive = searchParams.get('idLive');
  const getType = searchParams.get('type');
  // get data
  

  useEffect(() => {
    setLoading(true)
    if(!getIdUser) {
      setData(null)
      setLoading(false)
      navigate(`/stream/end?msg=${"idParamrequired"}`)
      return
    }
    if (getToken && getType) {
      // setType(getType)
      readRoom(dispatch, id, getIdUser, getIdLive, getToken, getType, el => {
        if (el.message === 'success') {
          setData({
            token: getToken,
            UserId: getIdUser,
            type: getType,
            userParticipant: el.data
          })
          // setError(null)
          setLoading(false)
          console.log({
            token: getToken,
            UserId: getIdUser,
            type: getType,
            userParticipant: el.data
          });
        } else {
          console.log(el)
          setData(null)
          setLoading(false)
          navigate(`/stream/end?msg=${"roomNotFound"}`)
        }
      })
    }else{
      setData(null)
      setLoading(false)
      navigate(`/stream/end?msg=${"tokenAndTypeRequired"}`)
    }
  }, [])
  return (
    <div>
      {
        loading ? <End/> :
          // data.type === 'participant' ? <Participant RoomId={getIdLive} data={data} /> : <NonParticipant RoomId={getIdLive} data={data} />
          <Participant RoomId={getIdLive} data={data} />
      }
    </div>
  )
}
