const initialState = {
    room: null,
    userParticipant: null,
    participant: null,
    messages: [],
}

function reducer(state = initialState, action) {
    const { type, payload } = action
    if(type === 'SET_ROOM') {
        return {
            ...state,
            room: payload
        }
    }
    if(type === 'INCOMING_CHAT_STREAM') {
        return {
            ...state,
            messages: [...state.messages, payload]
        }
    }
    if(type === 'SET_USER_PARTICIPANT') {
        return {
            ...state,
            userParticipant: payload
        }
    }
    if(type === 'SET_PARTICIPANT') {
        return {
            ...state,
            participant: payload
        }
    }
    if(type === 'EDIT_PARTICIPANT') {
        let tempParticipant = state.participant
        const findIndex = tempParticipant.findIndex(el => el.userId == payload.userId)
        if(state.participant) {
            if(findIndex>=0) {
                tempParticipant[findIndex] = payload
                
            }else{
                tempParticipant = [...tempParticipant, payload]
            }
        }else{
            tempParticipant = [payload]
        }
        return {
            ...state,
            participant: tempParticipant
        }
    }
    if(type === 'DELETE_PARTICIPANT') {
        return {
            ...state,
            participant: state.participant.filter(el => el.userId !== payload)
        }
    }
    if(type === 'RESET_ROOM') {
        return {
            room: null,
            participant: null,
            userParticipant: null,
            messages: []
        }
    }

    return state
}

export default reducer