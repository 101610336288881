import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home from "./Home";

import Stream from "./Stream";
import End from "./End";

import VidCall from "./Vidcall";
import VidcallEnd from "./VidcallEnd";

import Call from "./Call";
import CallEnd from "./CallEnd";

export default function MainRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="stream/:id" element={<Stream />} />
        <Route path="stream/end" element={<End />} />

        <Route path="vidcall/:id" element={<VidCall />} />
        <Route path="vidcall/end" element={<VidcallEnd />} />

        <Route path="call/:id" element={<Call />} />
        <Route path="call/end" element={<CallEnd />} />
        <Route path="*" element={<h1>no match</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
