import React from 'react'
import { MutatingDots } from 'react-loader-spinner';

export default function End() {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70%' }}>
            <MutatingDots
                height="100"
                width="100"
                color="#FE4C4C"
                secondaryColor='#F22A60'
                radius='12.5'
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}
