import React, { useEffect, useState, useRef } from 'react'
import {
  connectWs, connectPeer, endStream, kick, leave, changeAudio, changeVideo, sendChat
} from '../../helpers/stream'
import { setParticipant, editParticipant, deleteParticipant, setUserParticipant, resetRoom, incomingChatStream } from '../../store/stream/function'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EmojiPicker from "emoji-picker-react";


// new
import rowDown from '../../assets/rowdown.png';
import chat from '../../assets/chatIcon.png';
import camera from '../../assets/camIcon.png';
import mic from '../../assets/micIcon.png';
import user from '../../assets/userIcon.png';
import info from '../../assets/infoIcon.png';
import unmute from '../../assets/unmuteIcon.png';
import offcam from '../../assets/offcamIcon.png';
import ModalListParticipant from './ModalListParticipant'

export default function NonParticipant({ RoomId, data }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  // new 
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [WP, setWP] = useState(`${windowWidth.current}px`);
  const [HP, setHP] = useState(`${windowHeight.current}px`);
  const [hideMenu, sethideMene] = useState(true);
  const [modalChat, setmodalChat] = useState(false);
  const [mute, setMute] = useState(true)
  const [cam, setCam] = useState(true)
  const [inputMessage, setInputMessage] = useState("")
  const [showEmoji, setShowEmoji] = useState(false)
  const [modalparticipant, setmodalparticipant] = useState(false);
  // new 

  const { token, UserId, type } = data
  const { room, participant, userParticipant, messages } = useSelector(state => state.stream)
  const [loading, setLoading] = useState(true)

  const [localStream, setLocalStream] = useState(null)

  const localVideoRef = useRef({ current: null })
  const remoteVideoRef0 = useRef({ current: null })
  const remoteVideoRef1 = useRef({ current: null })
  const remoteVideoRef2 = useRef({ current: null })
  const remoteVideoRef3 = useRef({ current: null })
  const remoteVideoRef4 = useRef({ current: null })
  const remoteVideoRef5 = useRef({ current: null })

  function getLocalStream(cb) {
    navigator.mediaDevices.getUserMedia({
      video: false, audio: true,
    }).then((stream) => {
      stream.getAudioTracks()[0].enabled = false;
      setLocalStream(stream)
      cb(stream)
    }).catch(_ => {
      cb(null)
    })
  }

  function initiationStream(cb) {
    getLocalStream(_ => {
      connectWs(socket => {
        if (socket.connected) {
          connectPeer(null, peerData => {
            if (peerData && peerData.id) {
              const peer = peerData.peer
              window.peerId = peerData.id
  
              peer.on('call', (call) => {              
                // check partisipant data
                let peerId = call.peer
                let indexInput = null
                if (participant) {
                  const findIndex = participant.findIndex(el => el.peer_id == peerId)
                  if (findIndex >= 0) {
                    indexInput = findIndex
                  } else {
                    indexInput = participant.length
                  }
                } else {
                  indexInput = 0
                }
                // check partisipant data
                call.answer(null)
                call.on('stream', (incomingStream) => {
                  // dapet stream dari user yg telah join
                  playRemoteSreamRef(incomingStream, indexInput, null)
                })
              })
              socket.emit('join_room', { roomId: Number(RoomId), userId: Number(UserId), type: type, peerId: peerData.id });
              
              socket.on(`userLeaving-${RoomId}`, (data) => {
                // user telah meninggalkan room hapus data stream by id
                dispatch(editParticipant(data))
              })
              socket.on(`incoming-chat-stream-${RoomId}`, (data) => {
                addMessages(data)
              })
  
              // broadcast change audio dan video
              socket.on(`change-audio-${RoomId}`, (data) => {
                dispatch(editParticipant(data))
              })
              socket.on(`change-video-${RoomId}`, (data) => {
                dispatch(editParticipant(data))
              })
              // broadcast change audio dan video
  
              socket.on(`endstream-${RoomId}`, _ => {
                // stream disudahi hapus semua data / buat null
                dispatch(resetRoom())
                stopSreamRef(null, null)
                handleEndStream(null)
              })
              cb("success")
            } else {
              cb("FailedFetchData")
            }
          })
        } else {
          cb("FailedFetchData")
        }
      })
    })
  }

  useEffect(() => {
    if (room) {
      initiationStream(cb => {
        setLoading(false)
        if (cb !== "success") {
          handleEndStream(cb)
        }
      })
    }
  }, [room])

  useEffect(() => {
    if (localStream) {
      localVideoRef.current.srcObject = localStream
      localVideoRef.current.onloadmetadata = () => {
        localVideoRef.current.play()
      }
    }
  }, [localStream])

  function stopSreamRef(index, cb) {
    if (index == null) {
      remoteVideoRef1.current = null
      remoteVideoRef2.current = null
      remoteVideoRef3.current = null
      remoteVideoRef4.current = null
      remoteVideoRef5.current = null
    }
    if (index == 1) {
      remoteVideoRef1.current = null
    }
    if (index == 2) {
      remoteVideoRef2.current = null
    }
    if (index == 3) {
      remoteVideoRef3.current = null
    }
    if (index == 4) {
      remoteVideoRef4.current = null
    }
    if (index == 5) {
      remoteVideoRef5.current = null
    }
    cb && cb('success')
  }

  function playRemoteSreamRef(stream, index, cb) {
    if (index == 0) {
      remoteVideoRef0.current.srcObject = stream
      if (remoteVideoRef0.current.muted) {
        remoteVideoRef0.current.muted = false
      }
      remoteVideoRef0.current.onloadmetadata = () => {
        remoteVideoRef0.current.play()
        cb && cb('success')
      }
    }
    if (index == 1) {
      remoteVideoRef1.current.srcObject = stream
      if (remoteVideoRef1.current.muted) {
        remoteVideoRef1.current.muted = false
      }
      remoteVideoRef1.current.onloadmetadata = () => {
        remoteVideoRef1.current.play()
        cb && cb('success')
      }
    }
    if (index == 2) {
      remoteVideoRef2.current.srcObject = stream
      if (remoteVideoRef2.current.muted) {
        remoteVideoRef2.current.muted = false
      }
      remoteVideoRef2.current.onloadmetadata = () => {
        remoteVideoRef2.current.play()
        cb && cb('success')
      }
    }
    if (index == 3) {
      remoteVideoRef3.current.srcObject = stream
      if (remoteVideoRef3.current.muted) {
        remoteVideoRef3.current.muted = false
      }
      remoteVideoRef3.current.onloadmetadata = () => {
        remoteVideoRef3.current.play()
        cb && cb('success')
      }
    }
    if (index == 4) {
      remoteVideoRef4.current.srcObject = stream
      if (remoteVideoRef4.current.muted) {
        remoteVideoRef4.current.muted = false
      }
      remoteVideoRef4.current.onloadmetadata = () => {
        remoteVideoRef4.current.play()
        cb && cb('success')
      }
    }
    if (index == 5) {
      remoteVideoRef5.current.srcObject = stream
      if (remoteVideoRef5.current.muted) {
        remoteVideoRef5.current.muted = false
      }
      remoteVideoRef5.current.onloadmetadata = () => {
        remoteVideoRef5.current.play()
        cb && cb('success')
      }
    }
    cb && cb('error setup remote stream')
  }

  function handleEndStream(msg) {
    leave(_ => {
      if (msg) {
        navigate(`/stream/end?msg=${msg}`)
      } else {
        navigate(`/stream/end?msg=`)
      }
    })
  }

  function addMessages(data) {
    dispatch(incomingChatStream(data))
  }

  return (
    <div>
      <>
        <div style={{ height: HP, width: WP, padding: 0, justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
          <div className='row' style={{ height: '100%' }}>
            {
              participant && participant.length ? participant.map((el, index) => {
                if (index == 0) {
                  return (
                    <div key={index} className={
                      participant[index + 1] ?
                        'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                        'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
                    }>
                      {
                        !el.peer_id &&
                        <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '50%' }}>Menunggu....</p>
                      }
                      <video muted autoPlay ref={remoteVideoRef0} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none', }} />
                    </div>
                  )
                } else if (index == 1) {
                  return (
                    <div key={index} className={
                      participant[index + 1] ?
                        'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                        'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
                    }>
                      {
                        !el.peer_id &&
                        <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '50%' }}>Menunggu....</p>
                      }
                      <video muted autoPlay ref={remoteVideoRef1} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                    </div>
                  )
                } else if (index == 2) {
                  return (
                    <div key={index} className={
                      participant[index + 1] ?
                        'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                        'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
                    }>
                      {
                        !el.peer_id &&
                        <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '50%' }}>Menunggu....</p>
                      }
                      <video muted autoPlay ref={remoteVideoRef2} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                    </div>
                  )
                } else if (index == 3) {
                  return (
                    <div key={index} className='col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black'>
                      {
                        !el.peer_id &&
                        <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '50%' }}>Menunggu....</p>
                      }
                      <video muted autoPlay ref={remoteVideoRef3} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                    </div>
                  )
                } else {
                  return null
                }
              }) : <></>
            }
          </div>

          {/* button leave */}
          <div style={{ zIndex: 99, marginTop: '-20%', alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <button className='btn btn-danger rounded-circle btn-lg' onClick={() => {
              handleEndStream(null)
            }}>O</button>
          </div>
        </div >
      </>
      <video muted autoPlay ref={localVideoRef} style={{ width: '100%', height: '100%', display: 'none' }} />
    </div>
  )
}
