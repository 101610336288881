import React from 'react';
import MainRoute from './page'


function App() {
  return (
    <MainRoute />
  );
}

export default App;
