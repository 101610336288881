import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import leftRow from '../../assets/leftRow.png';
import kick from '../../assets/kick.png';
import user from '../../assets/userIcon.png';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useState, useRef, createRef } from 'react'
const ModalListParticipant = ({ showModal, handleCLoe, data, handleKick }) => {
    const windowWidth = useRef(window.innerWidth);
    const [WP, setWP] = useState(`${windowWidth.current}px`);
    return (
        <div className='mx-auto' style={{ width: WP - '2%' }}>
            <Modal show={showModal} onHide={handleCLoe} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <div className='d-flex justify-content-between'>
                        <img src={leftRow} alt='fff' style={{ width: '20%' }} onClick={handleCLoe} />
                        <h3 className='mt-2 ml-2'>Participant</h3>
                    </div>
                    {/* <Modal.Title>Participant</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        {
                            data&&data.length?data.map((el, index) => {
                                return(
                                    <Row style={{ backgroundColor: '#FAFAFA', borderRadius: 20 }} key={index}>
                                        <Col xs={3} md={3}>
                                            {
                                                el.img_path&&
                                                <img src={el.img_path} alt="img" style={{ width: 50, height: 50, borderRadius: 50, borderColor: 'red' }} />
                                            }
                                        </Col>
                                        <Col xs={7} md={2}>
                                            {el.fullname}
                                        </Col>
                                        <Col xs={2} md={2}>
                                            <div className='d-flex justify-content-center' style={{ backgroundColor: '#52575C', borderRadius: 50, marginTop: '50%' }} onClick={(e) => {
                                                e.preventDefault()
                                                handleKick(el.userId)
                                            }}>
                                                <img src={kick} alt="remote video background" style={{ width: 20, height: 20, borderColor: 'red' }} />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }):<></>
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalListParticipant;