import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    connectWs, connectPeer, changeAudio, leave
} from '../../helpers/call'

import user from '../../assets/userDummy.png';
import phone from '../../assets/phoneIcon.png';
import mic from '../../assets/voiceCall.png';
import unMic from '../../assets/unvoiceCall.png'
import speakers from '../../assets/speaker.png';
import unSpeaker from '../../assets/unSpeaker.png'

export default function Recipient({ RoomId, data }) {
    const { type, callerId, callerName, recipientId, recipientName, socketId } = data
    const [localStream, setLocalStream] = useState(null)
    const [mute, setMute] = useState(false)

    const [loading, setLoading] = useState(true)

    const localVideoRef = useRef(null)
    const remoteVideoRef = useRef(null)
    const navigate = useNavigate()

    const windowWidth = useRef(window.innerWidth);
    const windowHeight = useRef(window.innerHeight);
    const [WP, setWP] = useState(`${windowWidth.current}px`);
    const [HP, setHP] = useState(`${windowHeight.current}px`);

    const [speaker, setSpeaker] = useState(true);
    const [seconds, setSeconds] = useState(0);

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`;
        return formattedTime;
    };
    const padNumber = (number) => {
        return number.toString().padStart(2, '0');
    };

    // countdown calling
    useEffect(() => {
        if (!loading) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [loading]);

    function getLocalStream(cb) {
        navigator.mediaDevices.getUserMedia({
            // video: { width: 480, height: 360 }, audio: true,
            video: false, audio: true
        }).then((stream) => {
            setLocalStream(stream)
            cb(stream)
        }).catch(_ => {
            cb(null)
        })
    }

    function initiationStream(cb) {
        getLocalStream(resStream => {
            if (resStream) {
                connectWs(socket => {
                    if (socket.connected) {
                        connectPeer(resStream, async (peerData) => {
                            if (peerData && peerData.id) {
                                const { localStreamData } = peerData
                                const peer = peerData.peer
                                socket.emit('join_call', { roomId: RoomId, peerId: peerData.id, socketId: socket.id, callerId: callerId, callerSocketId: socketId }, null, (result) => {
                                    if (result === 'success') {
                                        setLoading(false)
                                        cb('success')
                                    } else {
                                        cb(result)
                                    }
                                });

                                socket.on(`userLeaving-${socketId}`, (data) => {
                                    // pemanggil telah leave
                                    endCall(null)
                                })

                                peer.on('call', (call) => {
                                    // jawab call dari pemanggil

                                    call.answer(localStreamData)
                                    call.on('stream', (incomingStream) => {
                                        // dapet stream dari penerima
                                        playRemoteSreamRef(incomingStream)
                                    })
                                })
                            } else {
                                cb("FailedFetchData");
                            }
                        })
                    } else {
                        cb("FailedFetchData");
                    }
                })
            } else {
                cb("FailedFetchData");
            }
        })
    }

    useEffect(() => {
        setMute(false)
        setLoading(true)
        initiationStream(cb => {
            if (cb !== "success") {
                endCall(cb)
            }
        })
    }, [])

    useEffect(() => {
        if (localStream) {
            localVideoRef.current.srcObject = localStream
            localVideoRef.current.onloadmetadata = () => {
                localVideoRef.current.play()
            }
        }
    }, [localStream])

    function playRemoteSreamRef(stream, cb) {
        remoteVideoRef.current.srcObject = stream
        if (remoteVideoRef.current.muted) {
            remoteVideoRef.current.muted = false
        }
        remoteVideoRef.current.onloadmetadata = () => {
            remoteVideoRef.current.play()
            cb && cb('success')
            return
        }
        cb && cb('error setup remote stream')
    }

    function endCall(msg) {
        leave(_ => {
            if (msg) {
                navigate(`/call/end?msg=${msg}`)
            } else {
                navigate(`/call/end?msg=`)
            }
        })
    }

    const handleClickMute = () => {
        changeAudio({
            status: !mute
        }, _ => {
            setMute(!mute)
        })
    }

    return (
        <div>
            {
                loading &&
                <div className='loading'>
                    <h1>loading...</h1>
                </div>
            }
            <>
                <div className='bodyGlobal' style={{ backgroundColor: '#eeeee4' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={user} alt="remote video background" style={{ width: 200, height: 200, borderRadius: '50%', marginTop: '30%' }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
                        <h4>{callerName}</h4>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                        {/* <h6>01:52:23</h6> */}
                        <h6>{formatTime(seconds)}</h6>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40%' }}>
                        {/* <button>Call</button> */}
                        <div style={{ backgroundColor: 'white', borderRadius: '50%', marginLeft: '10%', border: '2px solid #F22A60' }} onClick={(e) => {
                            e.preventDefault()
                            handleClickMute()
                        }}>
                            {
                                !mute ?
                                    <img src={mic}
                                        style={{ margin: 15, color: 'blue' }}
                                    />
                                    :
                                    <img src={unMic}
                                        style={{ margin: 15, color: 'blue' }}
                                    />

                            }
                        </div>
                        <div style={{ backgroundColor: 'red', borderRadius: '50%' }} onClick={(e) => {
                            e.preventDefault()
                            endCall(null)
                        }}>
                            <img src={phone}
                                style={{ margin: 15, color: 'blue' }}
                            />
                        </div>
                        <div id="loud-speaker" value={speaker} style={{ backgroundColor: 'white', borderRadius: '50%', marginRight: '10%', border: '2px solid #F22A60' }} onClick={() => {
                            setSpeaker(!speaker)
                        }}>
                            {
                                speaker ?
                                    <img src={speakers}
                                        style={{ margin: 15, color: 'blue' }}
                                    />
                                    :
                                    <img src={unSpeaker}
                                        style={{ margin: 15, color: 'blue' }}
                                    />

                            }
                        </div>
                    </div>
                </div>
            </>

            <video autoPlay muted ref={localVideoRef} style={{ display: 'none' }} />
            <video autoPlay muted ref={remoteVideoRef} style={{ display: 'none' }} />
        </div>
    )
}
