import React, { useEffect, useState, useRef } from 'react'
import {
  connectWs, connectPeer, endStream, kick, leave, changeAudio, changeVideo, sendChat
} from '../../helpers/stream'
import { setParticipant, editParticipant, deleteParticipant, setUserParticipant, resetRoom, incomingChatStream } from '../../store/stream/function'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EmojiPicker from "emoji-picker-react";


// new
import rowDown from '../../assets/rowdown.png';
import chat from '../../assets/chatIcon.png';
import camera from '../../assets/camIcon.png';
import mic from '../../assets/micIcon.png';
import user from '../../assets/userIcon.png';
import info from '../../assets/infoIcon.png';
import unmute from '../../assets/unmuteIcon.png';
import offcam from '../../assets/offcamIcon.png';
import Send from '../../assets/send.png'
import ModalListParticipant from './ModalListParticipant'


export default function Participant({ RoomId, data }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // new 
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [devices, setDevices] = useState([]);
  const [currentDeviceId, setCurrentDeviceId] = useState('');
  const [WP, setWP] = useState(`${windowWidth.current}px`);
  const [HP, setHP] = useState(`${windowHeight.current}px`);
  const [hideMenu, sethideMene] = useState(true);
  const [modalChat, setmodalChat] = useState(false);
  const [mute, setMute] = useState(true)
  const [cam, setCam] = useState(true)
  const [inputMessage, setInputMessage] = useState("")
  const [showEmoji, setShowEmoji] = useState(false)
  const [modalparticipant, setmodalparticipant] = useState(false);
  const [iconDown, seticonDown] = useState(true)
  // new 

  const { token, UserId, type } = data
  const { room, participant, userParticipant, messages } = useSelector(state => state.stream)
  const [loading, setLoading] = useState(true)

  const [localStream, setLocalStream] = useState(null)

  const localVideoRef = useRef({ current: null })
  const remoteVideoRef0 = useRef({ current: null })
  const remoteVideoRef1 = useRef({ current: null })
  const remoteVideoRef2 = useRef({ current: null })
  const remoteVideoRef3 = useRef({ current: null })
  const remoteVideoRef4 = useRef({ current: null })
  const remoteVideoRef5 = useRef({ current: null })

  useEffect(() => {
    const getDevices = async () => {
      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      alert(JSON.stringify(deviceInfos));
      const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        setCurrentDeviceId(videoDevices[0].deviceId);
      }
    };

    getDevices();
  }, []);

  function getLocalStream(cb) {
    console.log('current device id', currentDeviceId);
    // alert(JSON.stringify(devices));
    if(currentDeviceId && devices.length > 0){
      navigator.mediaDevices.getUserMedia({
        video: { width: 480, height: 360, deviceId: { exact: currentDeviceId } }, audio: true,
        // video: { width: 480, height: 360 }, audio: false,
      }).then((stream) => {
        if (userParticipant.audio === 'false') {
          setMute(false)
          stream.getAudioTracks()[0].enabled = false;
        } else {
          setMute(true)
        }
        if (userParticipant.video === 'false') {
          setCam(false)
          stream.getVideoTracks()[0].enabled = false;
        } else {
          setCam(true)
        }
        setLocalStream(stream)
        cb(stream)
      }).catch(_ => {
        console.log('error', _)
        cb(null)
      })
    }
    
  }

  function addMessages(data) {
    dispatch(incomingChatStream(data))
  }

  function initiationStream(cb) {
    getLocalStream(resStream => {
      if (resStream) {
        connectWs(socket => {
          if (socket.connected) {
            connectPeer(resStream, peerData => {
              if (peerData && peerData.id) {
                const { localStreamData } = peerData
                dispatch(setUserParticipant({ ...userParticipant, socket_id: socket.id, peer_id: peerData.id }))
                const peer = peerData.peer
                window.peerId = peerData.id

                peer.on('call', (call) => {
                  console.log('call', call)
                  // jawab call dari user yg telah join
                  let peerId = call.peer

                  // check partisipant data
                  let indexInput = null
                  console.log('peerId', peerId)
                  console.log('participant', participant)
                  if (participant) {
                    const findIndex = participant.findIndex(el => el.peer_id == peerId)
                    if (findIndex >= 0) {
                      indexInput = findIndex
                    } else {
                      indexInput = participant.length
                    }
                  } else {
                    indexInput = 0
                  }
                  console.log('indexInput', indexInput)
                  // check partisipant data

                  call.answer(localStreamData)
                  call.on('stream', (incomingStream) => {
                    // dapet stream dari user yg telah join
                    console.log('incomingStream', incomingStream)
                    if(incomingStream) {
                      playRemoteSreamRef(incomingStream, indexInput, null)
                    }
                  })
                })

                console.log('Number(UserId)', Number(UserId))
                
                socket.emit('join_room', { roomId: RoomId, userId: Number(UserId), type: type, peerId: peerData.id });
                socket.on(`joined-${RoomId}`, async (data) => {
                  // user telah join
                  console.log('join user')
                  if (window.peerId && data.peerId && window.peerId !== data.peerId) {
                    // manggil joined user
                    console.log('join user ok')
                    socket.emit('call_join_stream', { userId: Number(UserId) })
                    await peer.call(data.peerId, localStreamData)
                  }

                  // check partisipant data
                  let tempParticipant = participant
                  let indexInput = null
                  const findIndex = participant.findIndex(el => el.userId == data.partisipantData.userId)
                  if (participant) {
                    if (findIndex >= 0) {
                      tempParticipant[findIndex] = data.partisipantData
                      indexInput = findIndex
                    } else {
                      tempParticipant = [...tempParticipant, data.partisipantData]
                      indexInput = tempParticipant.length-1
                    }
                  } else {
                    tempParticipant = [data.partisipantData]
                    indexInput = 0
                  }
                  // check partisipant data
                  // console.log(tempParticipant);
                  // console.log(tempParticipant);

                  dispatch(setParticipant(tempParticipant))
                  const peerId = window.peerId
                  if (peerId && data.peerId && peerId !== data.peerId) {
                    // manggil joined user
                    const call = await peer.call(data.peerId, localStreamData)
                    call.on('stream', (incomingStream) => {
                      // dapet stream dari joined user
                      // console.log(incomingStream, indexInput, null);
                      playRemoteSreamRef(incomingStream, indexInput, null)
                    })
                  }
                })
                socket.on(`joined-guest-${RoomId}`, async (data) => {
                  // guest telah join
                  const peerId = window.peerId
                  if (peerId && data.peerId && peerId !== data.peerId) {
                    // manggil joined user
                    await peer.call(data.peerId, localStreamData)
                  }
                })
                socket.on(`userLeaving-${RoomId}`, (data) => {
                  // user telah meninggalkan room hapus data stream by id
                  dispatch(editParticipant(data))
                })
                socket.on(`incoming-chat-stream-${RoomId}`, (data) => {
                  addMessages(data)
                })


                // broadcast change audio dan video
                socket.on(`change-audio-${RoomId}`, (data) => {
                  dispatch(editParticipant(data))
                })
                socket.on(`change-video-${RoomId}`, (data) => {
                  dispatch(editParticipant(data))
                })
                // broadcast change audio dan video

                socket.on(`kick-${RoomId}`, (data) => {
                  // user telah dikick room hapus data stream by id
                  dispatch(deleteParticipant(data.userId))
                  if (data.userId == UserId) {
                    stopSreamRef(null, null)
                    dispatch(resetRoom())
                    handleEndStream("Kicked")
                  }
                })
                socket.on(`endstream-${RoomId}`, _ => {
                  // stream disudahi hapus semua data / buat null
                  dispatch(resetRoom())
                  stopSreamRef(null, null)
                  handleEndStream(null)
                })
                cb("success")
              } else {
                cb("FailedFetchData")
              }
            })
          } else {
            cb("FailedFetchDataSocketIO")
          }
        })
      } else {
        cb("FailedFetchDataLocalStream")
      }
    })
  }

  useEffect(() => {
    if (room) {
      initiationStream(cb => {
        setLoading(false)
        if (cb !== "success") {
          handleEndStream(cb)
        }
      })
    }
  }, [room, devices, currentDeviceId])
  
  const handleDeviceChange = (event) => {
    setCurrentDeviceId(event.target.value);
  };

  useEffect(() => {
    if (localStream) {
      localVideoRef.current.style.transform = 'scaleX(-1)';
      localVideoRef.current.srcObject = localStream
      localVideoRef.current.onloadmetadata = () => {
        localVideoRef.current.play()
      }
    }
  }, [localStream])

  function stopSreamRef(index, cb) {
    if (index == null) {
      localVideoRef.current = null
      remoteVideoRef1.current = null
      remoteVideoRef2.current = null
      remoteVideoRef3.current = null
      remoteVideoRef4.current = null
      remoteVideoRef5.current = null
    }
    if (index == 1) {
      remoteVideoRef1.current = null
    }
    if (index == 2) {
      remoteVideoRef2.current = null
    }
    if (index == 3) {
      remoteVideoRef3.current = null
    }
    if (index == 4) {
      remoteVideoRef4.current = null
    }
    if (index == 5) {
      remoteVideoRef5.current = null
    }
    cb && cb('success')
  }

  function playRemoteSreamRef(stream, index, cb) {
    if (index == 0) {
      remoteVideoRef0.current.srcObject = stream
      if (remoteVideoRef0.current.muted) {
        remoteVideoRef0.current.muted = false
      }
      remoteVideoRef0.current.onloadmetadata = () => {
        remoteVideoRef0.current.play()
        cb && cb('success')
      }
    }
    if (index == 1) {
      remoteVideoRef1.current.srcObject = stream
      if (remoteVideoRef1.current.muted) {
        remoteVideoRef1.current.muted = false
      }
      remoteVideoRef1.current.onloadmetadata = () => {
        remoteVideoRef1.current.play()
        cb && cb('success')
      }
    }
    if (index == 2) {
      remoteVideoRef2.current.srcObject = stream
      if (remoteVideoRef2.current.muted) {
        remoteVideoRef2.current.muted = false
      }
      remoteVideoRef2.current.onloadmetadata = () => {
        remoteVideoRef2.current.play()
        cb && cb('success')
      }
    }
    if (index == 3) {
      remoteVideoRef3.current.srcObject = stream
      if (remoteVideoRef3.current.muted) {
        remoteVideoRef3.current.muted = false
      }
      remoteVideoRef3.current.onloadmetadata = () => {
        remoteVideoRef3.current.play()
        cb && cb('success')
      }
    }
    if (index == 4) {
      remoteVideoRef4.current.srcObject = stream
      if (remoteVideoRef4.current.muted) {
        remoteVideoRef4.current.muted = false
      }
      remoteVideoRef4.current.onloadmetadata = () => {
        remoteVideoRef4.current.play()
        cb && cb('success')
      }
    }
    if (index == 5) {
      remoteVideoRef5.current.srcObject = stream
      if (remoteVideoRef5.current.muted) {
        remoteVideoRef5.current.muted = false
      }
      remoteVideoRef5.current.onloadmetadata = () => {
        remoteVideoRef5.current.play()
        cb && cb('success')
      }
    }
    cb && cb('error setup remote stream')
  }

  // new function
  const HandleClickModal = () => {
    sethideMene(!hideMenu);
  };
  const HadleClikUser = () => {
    setmodalparticipant(true);
    // sethideMene(!hideMenu);
  };

  const handleClickMute = () => {
    changeAudio({
      status: !mute, idUser: Number(UserId), roomId: RoomId
    }, _ => {
      dispatch(setUserParticipant({ ...userParticipant, audio: !mute }))
      setMute(!mute)
    })
  }

  const handleClickCam = () => {
    changeVideo({
      status: !cam, idUser: Number(UserId), roomId: RoomId
    }, _ => {
      dispatch(setUserParticipant({ ...userParticipant, video: !cam }))
      setCam(!cam)
    })
  }

  const HandleClickChat = () => {
    setmodalChat(true);
    sethideMene(false);
    seticonDown(false);

    const heightChatActive = windowHeight.current / 2;
    const widthChatActive = windowWidth.current / 2;
    setHP(`${heightChatActive}px`);
    setWP(`${widthChatActive}px`);
  };

  const closeModalChat = () => {
    setmodalChat(false);
    sethideMene(true)
    seticonDown(true);

    setHP(`${windowHeight.current}px`);
    setWP(`${windowWidth.current}px`);
  };

  const HandleKick = (id) => {
    kick({ roomId: RoomId, userId: Number(id) }, cb => {
      dispatch(deleteParticipant(Number(id)))
    })
  }

  function handleEndStream(msg) {
    leave(_ => {
      if (msg) {
        navigate(`/stream/end?msg=${msg}`)
      } else {
        navigate(`/stream/end?msg=`)
      }
    })
  }

  const sendByButtom = () => {
    const { userId, nickname } = userParticipant
    const inputChat = { roomId: RoomId, idUser: userId, name: nickname, message: inputMessage, createdAt: new Date() }
    sendChat(inputChat, _ => {
      addMessages(inputChat)
    })
    setInputMessage('')
  }

  const onEmojiClick = (emojiObject) => {
    console.log(emojiObject, 'emojiObject');
    // if (inputMessage && inputMessage.length < 90) {
    //   setInputMessage(`${inputMessage} ${emojiObject.emoji}`);
    //   setShowEmoji(false)
    // }
  };

  return (
    <div>
      <>
        <div style={{ height: HP, width: WP, padding: 0, justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
          {/* <div style={{ padding: 0 }} className='bodyGlobal'> */}
          <select onChange={handleDeviceChange} value={currentDeviceId}>
            {devices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </option>
            ))}
          </select>
          <ModalListParticipant showModal={modalparticipant} handleCLoe={() => setmodalparticipant(false)} data={participant} handleKick={HandleKick} />
          <>
            <div className='row' style={{ height: '100%' }}>
              <div className={
                participant && participant.length >= 2 ?
                  'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                  'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
              }>
                {
                  !cam &&
                  <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '25%'}}>
                    <img src={userParticipant.img_path} style={{ height: '50px', width: '50px', display: 'inline', borderRadius: '50px', objectFit: 'cover'}} />
                    <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '4%' }}>
                      {userParticipant.nickname}
                    </p>
                  </div>
                }
                <video muted autoPlay ref={localVideoRef} style={{ width: '100%', height: '100%' }} />
              </div>

              {
                participant && participant.length ? participant.map((el, index) => {
                  console.log(el)
                  if (index == 0) {
                    return (
                      <div key={index} className={
                        participant[index + 1] ?
                          'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                          'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
                      }>
                        {
                          (!el.peer_id || (el.video === 'false')) &&
                          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '25%'}}>
                            <img src={el.img_path} style={{ height: '50px', width: '50px', display: 'inline', borderRadius: '50px', objectFit: 'cover'}} />
                            <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '4%' }}>
                              {!el.peer_id && 'Menunggu '}{el.nickname}
                            </p>
                          </div>
                        }
                        <video muted autoPlay ref={remoteVideoRef0} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                      </div>
                    )
                  } else if (index == 1) {
                    return (
                      <div key={index} className={
                        participant[index + 1] ?
                          'col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black' :
                          'col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-black'
                      }>
                        {
                          (!el.peer_id || (el.video === 'false')) &&
                          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '25%'}}>
                            <img src={el.img_path} style={{ height: '50px', width: '50px', display: 'inline', borderRadius: '50px', objectFit: 'cover'}} />
                            <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '4%' }}>
                              {!el.peer_id && 'Menunggu '}{el.nickname}
                            </p>
                          </div>
                        }
                        <video muted autoPlay ref={remoteVideoRef1} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                      </div>
                    )
                  } else if (index == 2) {
                    return (
                      <div key={index} className='col-6 col-sm-6 col-md-6 col-lg-6 p-0 bg-black'>
                        {
                          (!el.peer_id || (el.video === 'false')) &&
                          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '25%'}}>
                            <img src={el.img_path} style={{ height: '50px', width: '50px', display: 'inline', borderRadius: '50px', objectFit: 'cover'}} />
                            <p className='text-white' style={{ textAlign: 'center', alignItems: 'center', marginTop: '4%' }}>
                              {!el.peer_id && 'Menunggu '}{el.nickname}
                            </p>
                          </div>
                        }
                        <video muted autoPlay ref={remoteVideoRef2} style={{ width: "100%", height: "100%", display: el.peer_id ? 'block' : 'none' }} />
                      </div>
                    )
                  } else {
                    return null
                  }
                }) : <></>
              }
            </div>
          </>


          {/* button leave */}
          <div style={{ zIndex: 99, marginTop: '-20%', alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <button className='btn btn-danger rounded-circle btn-lg' onClick={() => {
              if (room.creator_user_id == UserId) {
                endStream({ roomId: RoomId }, _ => {
                  navigate(`/stream/end?msg=`)
                })
              } else {
                handleEndStream(null)
              }
            }}>O</button>
          </div>
          {
            hideMenu ? (
              // <div style={{ zIndex: 99, buttom: 0, alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginRight: '5%', position: 'relative' }} className='d-flex flex-column'>
              <div style={{ zIndex: 99, marginTop: '-60%', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginRight: '5%', position: 'relative' }} className='d-flex flex-column'>
                <img src={info} alt="remote video background" style={{ marginBottom: 10 }} onClick={() => {
                  HadleClikUser()
                }} />
                <img src={user} alt="remote video background" style={{ marginBottom: 10 }} onClick={() => {
                  HadleClikUser()
                }} />
                <img src={cam ? camera : offcam} alt="remote video background" style={{ marginBottom: 10 }} onClick={(e) => {
                  e.preventDefault()
                  handleClickCam()
                }} />
                <img src={mute ? mic : unmute} alt="remote video background" style={{ marginBottom: 10 }} onClick={(e) => {
                  e.preventDefault()
                  handleClickMute()
                }} />
                <img src={chat} alt="remote video background" style={{ marginBottom: 10 }} onClick={() => {
                  HandleClickChat()
                }} />
                {iconDown ?
                  <img src={rowDown} alt="remote video background" onClick={() => {
                    HandleClickModal()
                  }} />
                  : null}
              </div>
            ) : (
              <div style={{ zIndex: 99, marginTop: '-20%', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginRight: '5%', position: 'relative' }} className='d-flex flex-column'>
                {iconDown ?
                  <img src={rowDown} alt="remote video background" onClick={() => {
                    HandleClickModal()
                  }} />
                  : null}
              </div>
            )
          }
        </div >
        {
          modalChat ? (
            <div style={{}}>
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', padding: 10 }} onClick={() => { closeModalChat() }}>X</div>
              <div style={{ height: 300, padding: 5, overflow: 'scroll' }}>
                {
                  messages && messages.length ? messages.sort((a, b) => a.createdAt - b.createdAt).map((item, itemIndex) => {
                    return (
                      <div key={itemIndex} className=" mb-2" style={{ backgroundColor: '#e8e8e8', padding: 10, borderTopRightRadius: 20, borderTopLeftRadius: 20, borderBottomRightRadius: 20, borderBottomLeftRadius: 10 }}>
                        {/* <div key={itemIndex} className="col-12 p-0 pl-1 pb-1 d-flex align-items-center"> */}
                        <div>
                          {item.name}
                        </div>
                        <div>
                          {item.message}
                        </div>
                      </div>
                    );
                  }) : null
                }
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const { userId, nickname } = userParticipant
                  const inputChat = { roomId: RoomId, idUser: userId, name: nickname, message: inputMessage, createdAt: new Date() }
                  sendChat(inputChat, _ => {
                    addMessages(inputChat)
                  })
                  setInputMessage('')
                }}
                autoComplete="off"
              >
                {
                  showEmoji &&
                  <div
                    className="row mx-auto"
                    style={{ position: "absolute", zIndex: "999", top: '30%' }}
                  >
                    <div className="col-6 col-md-6">
                      <EmojiPicker onEmojiClick={onEmojiClick} disableSearchBar />
                    </div>
                  </div>
                }
                <div className='row mx-1 mx-1'>
                  <div className=' col-8 col-md-8'>
                    <input type='text' className='form-control' placeholder='Ketik Pesan...' value={inputMessage} name="message" maxLength={90}
                      autoComplete="false"
                      onChange={(e) => setInputMessage(e.target.value)}
                      // disabled={isLoading ? true : false}
                      required
                    />
                  </div>
                  {/* <div className=' col-2 col-md-2 ' style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="fas fa-smile-beam" onClick={() => setShowEmoji(!showEmoji)}></i>
                  </div> */}
                  <div className='col-2 col-md-2' style={{ display: 'flex', alignItems: 'center' }} onClick={() => sendByButtom()}>
                    <img src={Send} />
                  </div>
                </div>
              </form>
            </div>
            // <div style={{ height: HP, width: '100%', padding: 0, marginTop: '5%', borderTopRightRadius: 25, borderTopLeftRadius: 25 }} >
            //   <div className='d-flex justify-content-between'>
            //     <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

            //       <div style={{ height: "300px", borderRadius: "5px" }}>
            //         <div style={{ overflow: "auto", transform: "scale(1, -1)", height: "300px" }}>
            //           <div style={{ transform: "scale(1, -1)" }}>
            //             {
            //               messages && messages.length ? messages.sort((a, b) => a.createdAt - b.createdAt).map((item, itemIndex) => {
            //                 return (
            //                   <div key={itemIndex} className="col-12 p-0 pl-1 pb-1 d-flex align-items-center">
            //                     {item.name} : {item.message}
            //                   </div>
            //                 );
            //               }) :
            //                 <div className="col-12 p-0 pl-1 pb-1 d-flex align-items-center">
            //                   no chat
            //                 </div>
            //             }
            //           </div>
            //         </div>
            //       </div>

            //       <div className="mt-2 mx-2">
            //         <form
            //           onSubmit={(e) => {
            //             e.preventDefault();
            //             const { userId, nickname } = userParticipant
            //             const inputChat = { roomId: RoomId, idUser: userId, name: nickname, message: inputMessage, createdAt: new Date() }
            //             sendChat(inputChat, _ => {
            //               addMessages(inputChat)
            //             })
            //           }}
            //           autoComplete="off"
            //         >
            //           <div className="">
            //             <div className="input-group"
            //               style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            //             >
            //               <input
            //                 type="text"
            //                 className="form-control"
            //                 value={inputMessage}
            //                 name="message"
            //                 placeholder="Press Enter To Send Message ..."
            //                 maxLength={90}
            //                 autoComplete="false"
            //                 onChange={(e) => setInputMessage(e.target.value)}
            //                 // disabled={isLoading ? true : false}
            //                 required
            //                 style={{ border: "1px solid #161616" }}
            //               />
            //               <div>
            //                 <div
            //                   className={'p-2'}
            //                 >
            //                   {90 - inputMessage.length >= 0
            //                     ? 90 - inputMessage.length
            //                     : 0}
            //                 </div>
            //               </div>
            //               <div className="pr-2">
            //                 <button
            //                   type="button"
            //                   onClick={() => setShowEmoji(!showEmoji)}
            //                   className="btn"
            //                   style={{ color: "#f9ba42" }}
            //                 >
            //                   <i className="fas fa-smile-beam"></i>
            //                 </button>
            //               </div>
            //             </div>
            //           </div>

            //           {
            //             showEmoji &&
            //             <div
            //               className="row"
            //               style={{ position: "absolute", right: "0", zIndex: "999" }}
            //             >
            //               <div className="col-12">
            //                 <EmojiPicker onEmojiClick={onEmojiClick} disableSearchBar />
            //               </div>
            //             </div>
            //           }
            //         </form>
            //       </div>
            //       Live Chat
            //     </div>
            //     <div style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginRight: '4%' }} onClick={() => {
            //       closeModalChat()
            //     }}>
            //       X
            //     </div>
            //   </div>
            // </div >
          ) : ''
        }
      </>
    </div >
  )
}

