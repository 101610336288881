import socketio from 'socket.io-client'
import { baseUrlWs, baseUrlPeer, serverBackend } from '../store';

let socket;
let peer;
let peerId;
let localStreamData;

export const connectWs = (cb) => {
    if(!socket) {
        socket = socketio.connect(baseUrlWs, {
            reconnection: true,
            autoConnect: true,
        })
        if (socket) {
            if (!socket.connected) {
                socket.on('connect', _=> {
                    // console.log("konek ulang", socket);
                    cb(socket)
                })
                socket.on("error", (err) => {
                    cb(null)
                    // console.log(`connect_error due to ${err.message}`);
                });
            } else {
                cb(socket)
            }
        } else {
            cb(null)
        }
    }else{
        cb(socket)
    } 
};

export const connectPeer = (stream , cb) => {
    localStreamData = stream
    if(!peer&&!peerId) {
        if(serverBackend==='local') {
            peer = new window.Peer(undefined, {
                path: '/peerjs',
                host: '/',
                port: 4001
            })
        }else{
            peer = new window.Peer(undefined, {
                path: '/klopin',
                host: baseUrlPeer,
                port: 9001,
                secure: true,
                debug: 3,
                config: {
                    iceServers: [
                        {
                            urls: [
                                'stun:185.210.144.14',
                            ],
                        },
                        {
                            url: 'turn:185.210.144.14',
                            username: 'klopinaj',
                            credential: 'klopinaj',
                        },
                    ],
                },
            })
        }
        
        peer.on('open', (id) => {
            peerId=id
            cb({id, peer, localStreamData})
        })
    }else{
        cb({peerId, peer, localStreamData})
    }
};

export const changeAudio = (data, cb) => {
    const { status } = data
    if(status) {
        localStreamData.getAudioTracks()[0].enabled = false;
        cb&&cb("success")
    }else{
        localStreamData.getAudioTracks()[0].enabled = true;
        cb&&cb("success")
    }
}

export const changeVideo = (data, cb) => {
    const {status, idUser, roomId} = data
    if(status) {
        localStreamData.getVideoTracks()[0].enabled = true;
        socket.emit('change-cam-vidcall', {
            status: 'true', idUser, roomId
        })
        cb&&cb("success")
    }else{
        localStreamData.getVideoTracks()[0].enabled = false;
        socket.emit('change-cam-vidcall', {
            status: 'false', idUser, roomId
        })
        cb&&cb("success")
    }
}

export const changeVideoSource = ({stream, idUser, roomId}) => {
    console.log('changeVideoSource');
    console.log('stream', stream);
    socket.emit('change-cam-source-vidcall', {
        stream, idUser, roomId
    })
}

export const leave = (cb) => {
    if(localStreamData) {
        localStreamData.getTracks().forEach( (track) => {
            track.stop();
        });
    }
    if(socket){
        socket.disconnect()
    }
    if(peer){
        peer.disconnect();
    }
    cb&&cb('success')
}
