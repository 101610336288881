import socketio from 'socket.io-client'
import { baseUrlPeer, baseUrlWs, serverBackend } from '../store';

let socket;
let peer;
let peerId;
let localStreamData;

export const connectWs = (cb) => {
    if(!socket) {
        socket = socketio.connect(baseUrlWs, {
            reconnection: true,
            autoConnect: true,
        })
        if (socket) {
            if (!socket.connected) {
                socket.on('connect', _=> {
                    cb(socket)
                })
                socket.on("error", (err) => {
                    cb(null)
                });
            } else {
                cb(socket)
            }
        } else {
            cb(null)
        }
    }else{
        cb(socket)
    } 
};

export const connectPeer = (stream , cb) => {
    localStreamData = stream
    if(!peer&&!peerId) {
        if(serverBackend==='local') {
            peer = new window.Peer(undefined, {
                path: '/peerjs',
                host: '/',
                port: 4001
            })
        }else{
            peer = new window.Peer(undefined, {
                path: '/klopin',
                host: baseUrlPeer,
                port: 9001,
                secure: true,
                debug: 3,
                config: {
                    iceServers: [
                        {
                            urls: [
                                'stun:185.210.144.14',
                            ],
                        },
                        {
                            url: 'turn:185.210.144.14',
                            username: 'klopinaj',
                            credential: 'klopinaj',
                        },
                    ],
                },
            })
        }
        
        peer.on('open', (id) => {
            peerId=id
            cb({id, peer, localStreamData})
        })
    }else{
        cb({peerId, peer, localStreamData})
    }
};


export const endStream = (data, cb) => {
    socket.emit('endstream', data)
    if(localStreamData) {
        localStreamData.getTracks().forEach( (track) => {
            track.stop();
        });
    }
    socket.disconnect()
    peer.disconnect();
    cb&&cb('success')
}

export const kick = (data, cb) => {
    socket.emit('kick', data)
    cb&&cb('success')
}

export const leave = (cb) => {
    if(localStreamData) {
        localStreamData.getTracks().forEach( (track) => {
            track.stop();
        });
    }
    if(socket){
        socket.disconnect()
    }

    if(peer){
        peer.disconnect();
    }
    cb&&cb('success')
}


export const changeAudio = (data, cb) => {
    const {status, idUser, roomId} = data
    if(status) {
        localStreamData.getAudioTracks()[0].enabled = true;
        socket.emit('change-audio', {
            status: 'true', idUser, roomId
        })
        cb&&cb("success")
    }else{
        localStreamData.getAudioTracks()[0].enabled = false;
        socket.emit('change-audio', {
            status: 'false', idUser, roomId
        })
        cb&&cb("success")
    }
}
export const changeVideo = (data, cb) => {
    const {status, idUser, roomId} = data
    if(status) {
        localStreamData.getVideoTracks()[0].enabled = true;
        socket.emit('change-video', {
            status: 'true', idUser, roomId
        })
        cb&&cb("success")
    }else{
        localStreamData.getVideoTracks()[0].enabled = false;
        socket.emit('change-video', {
            status: 'false', idUser, roomId
        })
        cb&&cb("success")
    }
}

export const sendChat = (data, cb) => {
    const { roomId, idUser, name, message, createdAt } = data
    socket.emit('send-chat-stream', {
        roomId, idUser, message, name: name?name:'anonymous', createdAt
    })
    cb&&cb("success")
}
