import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    connectWs, connectPeer, changeAudio, leave, changeVideo
} from '../../helpers/vidcall'

import VidcallEnd from '../../page/VidcallEnd'

import phone from '../../assets/phoneIcon.png';
import mic from '../../assets/voiceCall.png';
import unMic from '../../assets/unvoiceCall.png'

import Video from '../../assets/video-cameraPink.png'
import UnVideo from '../../assets/no-videoPink.png'
import Switch from '../../assets/switch-cameraPink.png'

export default function Recipient({ RoomId, data }) {
    const { type, callerId, callerName, recipientId, recipientName, socketId } = data
    const [localStream, setLocalStream] = useState(null)
    const [mute, setMute] = useState(false)

    const [loading, setLoading] = useState(true)
    const [buttonHidden, setbuttonHidden] = useState(true)

    const [cam, setCam] = useState(true)
    const [camRemote, setCamRemote] = useState(true)
    const [camType, setCamType] = useState(true)
    const [seconds, setSeconds] = useState(0);

    const localVideoRef = useRef(null)
    const remoteVideoRef = useRef(null)
    const navigate = useNavigate()

    function getLocalStream(cb) {
        navigator.mediaDevices.getUserMedia({
            video: { width: 480, height: 360 }, audio: true
        }).then((stream) => {
            setLocalStream(stream)
            cb(stream)
        }).catch(_ => {
            cb(null)
        })
    }

    function initiationStream(cb) {
        getLocalStream(resStream => {
            if (resStream) {
                connectWs(socket => {
                    if (socket.connected) {
                        connectPeer(resStream, async (peerData) => {
                            if (peerData && peerData.id) {
                                const { localStreamData } = peerData
                                const peer = peerData.peer

                                socket.emit('join_vidcall', { roomId: RoomId, peerId: peerData.id, socketId: socket.id, callerId: callerId, callerSocketId: socketId }, null, (result) => {
                                    if (result === 'success') {
                                        setLoading(false)
                                        cb('success')
                                    } else {
                                        cb(result)
                                    }
                                });

                                socket.on(`userLeaving-${socketId}`, (data) => {
                                    // pemanggil telah leave
                                    endCall(null)
                                })

                                socket.on(`change-cam-vidcall-${RoomId}`, (data) => {
                                    // cam pemanggil dimatiin
                                    if (data.idUser && data.idUser == callerId) {
                                        if (data.status == 'true') {
                                            setCamRemote(true)
                                        } else {
                                            setCamRemote(false)
                                        }
                                    }
                                })

                                socket.on(`change-cam-source-vidcall-${RoomId}`, ({stream}) => {
                                    console.log('change-cam-source stream', stream);
                                    playRemoteSreamRef(stream);
                                })

                                peer.on('call', (call) => {
                                    // jawab call dari pemanggil

                                    call.answer(localStreamData)
                                    call.on('stream', (incomingStream) => {
                                        // dapet stream dari penerima
                                        console.log('incomingStream', incomingStream);
                                        playRemoteSreamRef(incomingStream)
                                    })
                                })
                                cb('success')
                            } else {
                                cb("FailedFetchData");
                            }
                        })
                    } else {
                        cb("FailedFetchData");
                    }
                })
            } else {
                cb("FailedFetchData");
            }
        })
    }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`;
        return formattedTime;
    };

    const padNumber = (number) => {
        return number.toString().padStart(2, '0');
    };

    // countdown calling
    useEffect(() => {
        if(!loading) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
            setTimeout(() => {
                setbuttonHidden(false);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [loading, buttonHidden]);

    useEffect(() => {
        setLoading(true)
        setCamRemote(true)
        setMute(false)
        initiationStream(cb => {
            if (cb !== "success") {
                endCall(cb)
            }
        })
    }, [])

    useEffect(() => {
        if (localStream) {
            localVideoRef.current.srcObject = localStream
            localVideoRef.current.style.transform = 'scaleX(-1)';
            localVideoRef.current.onloadmetadata = () => {
                localVideoRef.current.play()
            }
        }
    }, [localStream])

    function playRemoteSreamRef(stream, cb) {
        remoteVideoRef.current.srcObject = stream
        if (remoteVideoRef.current.muted) {
            remoteVideoRef.current.muted = false
        }
        remoteVideoRef.current.onloadmetadata = () => {
            remoteVideoRef.current.play()
            cb && cb('success')
            return
        }
        cb && cb('error setup remote stream')
    }

    function endCall(msg) {
        leave(_ => {
            if (msg) {
                navigate(`/vidcall/end?msg=${msg}`)
            } else {
                navigate(`/vidcall/end?msg=`)
            }
        })
    }

    const handleClickMute = () => {
        changeAudio({
            status: !mute
        }, _ => {
            setMute(!mute)
        })
    }

    const handleClickCam = () => {
        changeVideo({
            status: !cam, idUser: Number(recipientId), roomId: Number(RoomId)
        }, _ => {
            setCam(!cam)
        })
    }

    return (
        <>
            {
                loading &&
                <VidcallEnd/>
            }
            <div className='bodyGlobal' style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', display: loading?'none':'block' }}>
                <div>
                    <div className='videoCallConatainer' onClick={() => setbuttonHidden(true)}>
                        {
                            !camRemote&&
                            <div className='VideoComponent' style={{ backgroundColor: '#787675', display: 'flex', width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', zIndex: 99, marginTop: '5%', backgroundColor: '#FFFFFF32', marginLeft: '5%', padding: 2, borderRadius: 20, width: '50%' }}>
                                    <h5 style={{ color: '#ffff', fontSize: 15 }}>{callerName} Mematikan Kamera</h5>
                                </div>
                            </div>
                        }
                        <video className='VideoComponent' autoPlay muted ref={remoteVideoRef} style={{ width: "100%", height: "100%", display: !camRemote ? 'none' : 'block' }} />
                    </div>
                    {/* element panah bawah dan kotak kecil */}
                    <div className='HeaderVcall'>
                        {
                            !cam&&
                            <div className='VideoComponent' style={{ backgroundColor: '#787675', display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                <img src={UnVideo} />
                            </div>
                        }
                        <video className='VideoComponent' autoPlay muted ref={localVideoRef} style={{ width: 100, height: 100, display: !cam ? 'none' : 'block' }} />
                    </div>

                    {/* element nama penerima dan timer */}
                    {buttonHidden ?
                        <div className='nameComponent' style={{ zIndex: 99, marginTop: '110%', marginLeft: '2%' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5%' }}>
                                <h4 style={{ color: 'white' }}>{callerName}</h4>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '1%' }}>
                                <h6 style={{ color: 'white' }}>{formatTime(seconds)}</h6>
                            </div>
                        </div>
                        : null}
                    {/* elemet list button */}
                    {buttonHidden ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', zIndex: 99, marginTop: '5%', backgroundColor: '#FFFFFF32', marginLeft: '5%', padding: 10, borderRadius: 20, width: '90%' }}>
                            <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'red', borderRadius: '50%' }} onClick={(e) => {
                                e.preventDefault()
                                endCall(null)
                            }}>
                                <img src={phone} style={{ padding: 3 }} />
                            </div>
                            <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }} onClick={(e) => {
                                e.preventDefault()
                                handleClickMute()
                            }}>
                                <img src={mute ? mic : unMic} style={{ padding: 5 }} />
                            </div>
                            <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }} onClick={(e) => {
                                e.preventDefault()
                                handleClickCam()
                            }}>
                                <img src={cam ? Video : UnVideo} style={{ padding: 3 }} />
                            </div>
                            <div id="cam-type" style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }}>
                                <img src={Switch} style={{ padding: 3 }} />
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}
