const initialState = {
    chats: null,
    unread: null,
    totalUnread: null,
    totalMessage: null,
    // messageCounter: 0,
    // historyChat: [],
    // conversation: [],
    // latestMessage: {},
    // selectedChats: [],
    // selectedFriends: []
}

function reducer(state = initialState, action) {
    const { type, payload } = action
    if(type === 'SET_CHAT') {
        return {
            ...state,
            ...payload
        }
    }
    if(type === 'SEND_CHAT') {
        let tempChat = state.chats
        if(tempChat[Number(payload.user_recipient_id)]) {
            tempChat[Number(payload.user_recipient_id)] = [payload, ...tempChat[Number(payload.user_recipient_id)]]
        }else{
            tempChat[Number(payload.user_recipient_id)] = [payload]
        }
        return {
            ...state,
            chats: tempChat
        }
    }
    if(type === 'INCOMING_CHAT') {
        let tempChat = state.chats
        if(tempChat[payload.user_sender_id]) {
            tempChat[payload.user_sender_id] = [payload, ...tempChat[payload.user_sender_id]]
        }else{
            tempChat[payload.user_sender_id] = [payload]
        }
        return {
            ...state,
            chats: tempChat
        }
    }
    if(type === 'READ_CHAT') {
        let tempChat = state.chats
        if(tempChat[payload]) {
            tempChat[payload] = tempChat[payload].map(temp => {
                temp.read_at = new Date()
                return temp
            })
        }
        return {
            ...state,
            chats: tempChat
        }
    }

    return state
}

export default reducer