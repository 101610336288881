import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    connectWs, connectPeer, changeAudio, leave, changeVideo, changeVideoSource
} from '../../helpers/vidcall'
import Webcam from "react-webcam";
import { baseKlopin } from '../../store';

import phone from '../../assets/phoneIcon.png';
import mic from '../../assets/voiceCall.png';
import unMic from '../../assets/unvoiceCall.png'

import Video from '../../assets/video-cameraPink.png'
import UnVideo from '../../assets/no-videoPink.png'
import Switch from '../../assets/switch-cameraPink.png'

export default function Caller({ RoomId, data }) {
    const { type, callerId, callerName, recipientId, recipientName } = data
    const [counter, setCounter] = useState(100);
    const [isConnect, setIsConnect] = useState(false);
    const [localStream, setLocalStream] = useState(null)
    const [mute, setMute] = useState(false)
    const [cam, setCam] = useState(true)
    const [buttonHidden, setbuttonHidden] = useState(true);
    const [cameras, setCameras] = useState(null);
    const [mirror, setMirror] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState(null);

    const [camRemote, setCamRemote] = useState(true)

    const localVideoRef = useRef(null)
    const connectedLocalVideoRef = useRef(null)
    const remoteVideoRef = useRef(null)
    const navigate = useNavigate()
    
    const [seconds, setSeconds] = useState(0);

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`;
        return formattedTime;
    };

    const padNumber = (number) => {
        return number.toString().padStart(2, '0');
    };

    function getLocalStream(cb) {
        if(cameras){
            let config = {
                video: { 
                    width: 480, 
                    height: 360,
                    deviceId: { 
                        exact: cameras[0].deviceId
                    }
                },
                audio: true,
            }
    
            if(selectedCamera){
                config.video.deviceId = { 
                    exact: selectedCamera
                }
            }
    
            console.log('config', config);
    
            navigator.mediaDevices.getUserMedia(config).then((stream) => {
                if(!selectedCamera){
                    const videoTrack = stream.getVideoTracks()[0]; // Assuming there is only one video track
                    const deviceId = videoTrack.getSettings().deviceId;

                    console.log('videoTrack.getSettings()', videoTrack.getSettings())
                    
                    setSelectedCamera(deviceId);
                }
                setLocalStream(stream)
                cb(stream)
            }).catch(_ => {
                cb(null)
            })
        }
    }

    const switchCamera = async () => {
        if (cameras.length < 2) {
          console.log('No other cameras available to switch to.');
          return;
        }

        console.log('before selectedCamera', selectedCamera);

        const currentCameraIndex = cameras.findIndex(camera => camera.deviceId === selectedCamera);
        const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
        const nextCamera = cameras[nextCameraIndex];

        console.log(cameras);
        console.log(nextCamera.deviceId);
    
        setSelectedCamera(nextCamera.deviceId);

    };

    function callingHandler(data, cb) {
        console.log(data.socketId, "socketId");
        baseKlopin.post("/VideoCall/calling", {
            callerID: data.callerId,
            socketID: data.socketId,
            recipientID: data.recipientId,
            callerName: data.callerName,
            recipientName: data.recipientName,
            roomID: RoomId,
        }).then(res => {
            console.log("sukses");
            cb('success')
        }).catch(err => {
            cb('error')
        })
    }

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
          const cameraList = devices.filter(device => device.kind === 'videoinput');
          setCameras(cameraList);
        });
    }, []);

    // countdown connecting
    useEffect(() => {
        let timer = null
        if (isConnect) {
            return
        }
        if (counter > 0) {
            timer = setInterval(() => setCounter(counter - 1), 1000)
            return () => clearInterval(timer);
        } else {
            endCall('CallNotAnswered')
            return
        }
    }, [counter]);

    // countdown calling
    useEffect(() => {
        if (isConnect) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
            setTimeout(() => {
                setbuttonHidden(false);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [isConnect, buttonHidden]);

    function initiationStream(cb) {
        getLocalStream(resStream => {
            console.log(resStream, "resStream");
            if (resStream) {
                connectWs(socket => {
                    if (socket.connected) {
                        connectPeer(resStream, peerData => {
                            if (peerData && peerData.id) {
                                socket.emit('make_call', { socketId: socket.id, caller_id: callerId, recipient_id: recipientId, type: 'video' }, null, (resultMakeCall) => {
                                    if (resultMakeCall === 'success') {
                                        callingHandler({
                                            recipientName, recipientId, callerId, callerName, socketId: socket.id
                                        }, resStatus => {
                                            if (resStatus === 'success') {
                                                const { localStreamData } = peerData
                                                const peer = peerData.peer
                                                window.peerId = peerData.id

                                                socket.on(`userJoinedVidCall-${RoomId}`, async (data) => {
                                                    // user telah join
                                                    setIsConnect(true)
                                                    if (data) {
                                                        if (data.socketId) {
                                                            socket.on(`userLeaving-${data.socketId}`, (data) => {
                                                                // penerima telah leave
                                                                endCall(null)
                                                            })
                                                        }
                                                        if (data.peerId) {
                                                            const call = await peer.call(data.peerId, localStreamData)
                                                            call.on('stream', (incomingStream) => {
                                                                // dapet stream dari pemanggil
                                                                playRemoteSreamRef(incomingStream)
                                                            })
                                                        }
                                                    }
                                                })

                                                socket.on(`change-cam-vidcall-${RoomId}`, (data) => {
                                                    // console.log("cam penerima dimatiin", data)
                                                    
                                                    if (data.idUser && data.idUser == recipientId) {
                                                        if (data.status == 'true') {
                                                            setCamRemote(true)
                                                        } else {
                                                            setCamRemote(false)
                                                        }
                                                    }
                                                })
                                                socket.on(`reject-vidcall-${RoomId}`, async (data) => {
                                                    // user mereject
                                                    endCall("CallRejected")
                                                })
                                                cb('success')
                                            } else {
                                                cb("FailedFetchData_4");
                                            }
                                        })
                                    } else {
                                        cb("FailedMakeACall");
                                    }
                                })
                            } else {
                                console.log(peerData);
                                // cb("FailedFetchData_3");
                            }
                        })
                    } else {
                        console.log(socket);
                        // cb("FailedFetchData_2");
                    }
                })
            } else {
                cb("FailedFetchData_1");
            }
        })
    }

    useEffect(() => {
        setCamRemote(true)
        setMute(false)
        setIsConnect(false)
    }, [])

    useEffect(() => {
        initiationStream(cb => {
            if (cb !== "success") {
                endCall(cb)
            }
        })
    }, [selectedCamera, cameras])

    useEffect(() => {
        if (localStream) {
            console.log('localStream', localStream);

            console.log('localVideoRef', localVideoRef);

            if(localVideoRef.current){
                console.log('localVideoRef.current');
                localVideoRef.current.srcObject = localStream;
                localVideoRef.current.onloadmetadata = () => {
                    localVideoRef.current.play()
                }
            }
            
            console.log('connecteLocalVideoRef', connectedLocalVideoRef);

            if(connectedLocalVideoRef.current){
                console.log('connecteLocalVideoRef.current');
                connectedLocalVideoRef.current.srcObject = localStream;
                connectedLocalVideoRef.current.style.transform = 'scaleX(-1)';
                connectedLocalVideoRef.current.onloadmetadata = () => {
                    connectedLocalVideoRef.current.play()
                }


            }
        }
    }, [localStream])

    function playRemoteSreamRef(stream, cb) {
        remoteVideoRef.current.srcObject = stream
        if (remoteVideoRef.current.muted) {
            remoteVideoRef.current.muted = false
        }
        remoteVideoRef.current.onloadmetadata = () => {
            remoteVideoRef.current.play()
            cb && cb('success')
            return
        }
        cb && cb('error setup remote stream')
    }

    function endCall(msg) {
        leave(_ => {
            if (msg) {
                navigate(`/vidcall/end?msg=${msg}`)
            } else {
                navigate(`/vidcall/end?msg=`)
            }
        })
    }

    const handleClickMute = () => {
        changeAudio({
            status: !mute
        }, _ => {
            setMute(!mute)
        })
    }

    const handleClickCam = () => {
        changeVideo({
            status: !cam, idUser: Number(callerId), roomId: Number(RoomId)
        }, _ => {
            setCam(!cam)
        })
    }


    return (
        <div className='bodyGlobal' style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
            {
                !isConnect&&
                <>
                    <Webcam
                        audio={false}
                        width={'100%'}
                        height={'100%'}
                        ref={localVideoRef}
                        screenshotFormat="image/jpeg"
                        mirrored={true}
                    />
                    <div style={{ zIndex: 99, position: 'absolute', width: '100%', height: '100%', top: 0, paddingTop: '50%', alignItems: 'center ' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h4 style={{ color: 'white' }}>{recipientName}</h4>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                            <h6 style={{ color: 'white' }}>Berdering...</h6>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70%' }}>
                            <div style={{ backgroundColor: 'red', borderRadius: '50%' }} onClick={(e) => {
                                e.preventDefault()
                                endCall(null)
                            }}>

                                <img src={phone}
                                    style={{ margin: 15, color: 'blue' }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
            <div style={{ display: !isConnect ? 'none' : 'block' }}>
                <div className='videoCallConatainer' onClick={() => setbuttonHidden(true)}>
                    {
                        !camRemote&&
                        <div className='VideoComponent' style={{ backgroundColor: '#787675', display: 'flex', width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', zIndex: 99, marginTop: '5%', backgroundColor: '#FFFFFF32', marginLeft: '5%', padding: 2, borderRadius: 20, width: '50%' }}>
                                <h5 style={{ color: '#ffff', fontSize: 15 }}>{recipientName} Mematikan Kamera</h5>
                            </div>
                        </div>
                    }
                    <video className='VideoComponent' autoPlay muted ref={remoteVideoRef} style={{ width: "100%", height: "100%", display: !camRemote ? 'none' : 'block' }} />
                </div>
                {/* element panah bawah dan kotak kecil */}
                <div className='HeaderVcall'>
                    {
                        !cam&&
                        <div className='VideoComponent' style={{ backgroundColor: '#787675', display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                            <img src={UnVideo} />
                        </div>
                    }
                    <video className='VideoComponent' autoPlay muted ref={connectedLocalVideoRef} style={{ width: 100, height: 100, display: !cam ? 'none' : 'block' }} />
                </div>

                {/* element nama penerima dan timer */}
                {buttonHidden ?
                    <div className='nameComponent' style={{ zIndex: 99, marginTop: '110%', marginLeft: '2%' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5%' }}>
                            <h4 style={{ color: 'white' }}>{recipientName}</h4>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '1%' }}>
                            <h6 style={{ color: 'white' }}>{formatTime(seconds)}</h6>
                        </div>
                    </div>
                    : null}
                {/* elemet list button */}
                {buttonHidden ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', zIndex: 99, marginTop: '5%', backgroundColor: '#FFFFFF32', marginLeft: '5%', padding: 10, borderRadius: 20, width: '90%' }}>
                        <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'red', borderRadius: '50%' }} onClick={(e) => {
                            e.preventDefault()
                            endCall(null)
                        }}>
                            <img src={phone} style={{ padding: 3 }} />
                        </div>
                        <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }} onClick={(e) => {
                            e.preventDefault()
                            handleClickMute()
                        }}>
                            <img src={mute ? mic : unMic} style={{ padding: 5 }} />
                        </div>
                        <div style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }} onClick={(e) => {
                            e.preventDefault()
                            handleClickCam()
                        }}>
                            <img src={cam ? Video : UnVideo} style={{ padding: 3 }} />
                        </div>
                        <div id="cam-type" style={{ marginTop: '1%', padding: 1, backgroundColor: 'white', borderRadius: '20%' }} onClick={switchCamera}>
                            <img src={Switch} style={{ padding: 3 }} />
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}
