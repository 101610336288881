import { baseKlopin } from "../index";

export function setRoom(val) {
    return { type: "SET_ROOM", payload: val };
}
export function setUserParticipant(val) {
    return { type: "SET_USER_PARTICIPANT", payload: val };
}
export function setParticipant(val) {
    return { type: "SET_PARTICIPANT", payload: val };
}
export function editParticipant(val) {
    return { type: "EDIT_PARTICIPANT", payload: val };
}
export function deleteParticipant(val) {
    return { type: "DELETE_PARTICIPANT", payload: val };
}
export function resetRoom() {
    return { type: "RESET_ROOM" };
}
export function incomingChatStream(val) {
    return { type: "INCOMING_CHAT_STREAM", payload: val };
}

export async function readRoom(dispatch, id, userId, liveId, token, typeUser, cb) {
    try {
        const res = await baseKlopin.get(`/live-klop/${liveId}`, {
            headers: {
                Authorization: 'Bearer ' + token 
            }
        });
        if(res.data) {
            const dataApi = res.data
            if(dataApi&&dataApi.data&&dataApi.message === "Success Get Live Klop") {
                const result = dataApi.data
                console.log('result', result)
                if(result.participants) {
                    let myData = null
                    let participant = []
                    result.participants.forEach(ele => {
                        if(ele.user.id == userId) {
                            myData = {
                                participantId:ele.id,
                                socket_id:ele.socket_id,
                                userId: ele.user.id,
                                fullname: ele.user.fullname,
                                nickname: ele.user.nickname,
                                img_path: ele.user.img_path,
                                klopin_id: ele.user.klopin_id,
                                peer_id: ele.peer_id,
                                no_hp: ele.user.no_hp,
                                audio: ele.audio,
                                video: ele.video
                            }
                        }else{
                            participant.push({
                                participantId:ele.id,
                                socket_id:ele.socket_id,
                                userId: ele.user.id,
                                fullname: ele.user.fullname,
                                nickname: ele.user.nickname,
                                img_path: ele.user.img_path,
                                klopin_id: ele.user.klopin_id,
                                peer_id: ele.peer_id,
                                no_hp: ele.user.no_hp,
                                audio: ele.audio,
                                video: ele.video
                            })
                        }
                    })
                    if(myData) {
                        dispatch(setUserParticipant(myData))
                        dispatch(setRoom({
                            id: result.id,
                            name: result.name,
                            status: result.status,
                            type: result.type,
                            creator_user_id: result.creator_user_id,
                            number_participants: result.number_participants
                        }))
                        dispatch(setParticipant(participant))
                        cb({ message: 'success', data: myData });
                    }else{
                        cb({ message: 'error this user not found on participant data', data: null })
                    }
                    // if(typeUser==="participant") {
                    //     if(myData) {
                    //         dispatch(setUserParticipant(myData))
                    //         dispatch(setRoom({
                    //             id: result.id,
                    //             name: result.name,
                    //             status: result.status,
                    //             type: result.type,
                    //             creator_user_id: result.creator_user_id,
                    //             number_participants: result.number_participants
                    //         }))
                    //         dispatch(setParticipant(participant))
                    //         cb({ message: 'success', data: myData });
                    //     }else{
                    //         cb({ message: 'error this user not found on participant data', data: null })
                    //     }
                    // }else{
                    //     if(myData) {
                    //         cb({ message: 'error this room not for particpant', data: null })
                    //     }else{
                    //         if(result.type === "public") {
                    //             dispatch(setRoom({
                    //                 id: result.id,
                    //                 name: result.name,
                    //                 status: result.status,
                    //                 type: result.type,
                    //                 creator_user_id: result.creator_user_id,
                    //                 number_participants: result.number_participants
                    //             }))
                    //             dispatch(setParticipant(participant))
                    //             console.log(participant);
                    //             console.log(myData);
                    //             cb({ message: 'success', data: myData });
                    //         }else{
                    //             cb({ message: 'error this room not for public', data: null })
                    //         }
                    //     }
                    // }
                }else{
                    cb({ message: 'error', data: null })
                }
            }else{
                cb({ message: 'error', data: null })
            }
        }else{
            cb({ message: 'error', data: null })
        }
    } catch (err) {
        if(err.response) {
            cb({ message: err.response.data.message||'error', data: null })
        }else{
            cb({ message: 'error', data: null })
        }
    }
}