import io from 'socket.io-client'
import { baseUrlWs, serverBackend } from '../store';

let socket;
let peer;
let peerId;
let localStreamData;

export const connectWs = (cb) => {
    if(!socket) {
        socket = io(baseUrlWs, {
            path: '/klopin'
        })
        if (socket) {
            if (!socket.connected) {
                socket.on('connect', _=> {
                    // console.log("konek ulang", socket);
                    cb(socket)
                })
                socket.on("error", (err) => {
                    cb(null)
                    // console.log(`connect_error due to ${err.message}`);
                });
            } else {
                cb(socket)
            }
        } else {
            cb(null)
        }
    }else{
        cb(socket)
    } 
};

export const connectPeer = (stream , cb) => {
    localStreamData = stream
    if(!peer&&!peerId) {
        if(serverBackend==='local') {
            peer = new window.Peer(undefined, {
                path: '/peerjs',
                host: '/',
                port: 4001
            })
        }else{
            peer = new window.Peer(undefined, {
                path: '/peerjs',
                host: 'stream.psbb.id',
                // port: '4001'
            })
        }
        peer.on('open', (id) => {
            peerId=id
            cb({id, peer, localStreamData})
        })
    }else{
        cb({peerId, peer, localStreamData})
    }
};

export const changeAudio = (data, cb) => {
    const { status } = data
    if(status) {
        localStreamData.getAudioTracks()[0].enabled = false;
        cb&&cb("success")
    }else{
        localStreamData.getAudioTracks()[0].enabled = true;
        cb&&cb("success")
    }
}

export const leave = (cb) => {
    if(localStreamData) {
        localStreamData.getTracks().forEach( (track) => {
            track.stop();
        });
    }
    if(socket) {
        socket.disconnect()
    }
    if(peer) {
        peer.disconnect();
    }
    cb&&cb('success')
}
