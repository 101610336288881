import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import Caller from '../components/Call/Caller';
import Recipient from '../components/Call/Recipient';
import { useNavigate } from 'react-router-dom'
import CallEnd from './CallEnd'

export default function Call() {
    const { id } = useParams();
    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        setLoading(true)
        const type = searchParams.get('type');
        const callerId = searchParams.get('caller_id');
        const recipientId = searchParams.get('recipient_id');

        const callerName = searchParams.get('caller_name');
        const recipientName = searchParams.get('recipient_name');
        const socketId = searchParams.get('socket_id') || null

        if (!callerId && !recipientId && !callerName && !recipientName) {
            setData(null)
            setLoading(false)
            navigate(`/call/end?msg=${"paramRequired"}`)
            return
        }

        if (type === "recipient") {
            if (socketId) {
                setData({
                    type, callerId, callerName, recipientId, recipientName, socketId
                })
                setLoading(false)
            } else {
                setData(null)
                setLoading(false)
                navigate(`/call/end?msg=${"socketIdRequired"}`)
            }
        } else if (type === "caller") {
            setData({
                type, callerId, callerName, recipientId, recipientName, socketId: null
            })
            setLoading(false)
        } else {
            setData(null)
            setLoading(false)
            navigate(`/call/end?msg=${"typeIsWrong"}`)
        }
    }, [])

    return (
        <>
            {
                loading ?
                <CallEnd/> :
                data.type === 'caller' ? <Caller RoomId={id} data={data} /> : <Recipient RoomId={id} data={data} />
            }
        </>
    )
}
